.swiper {
	position: relative;
	width: 100%;
	// height: 300px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}
}
