.main-menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	height: 100dvh;
	z-index: 1001;
	background: #fff;
	padding: 2rem;

	display: none;
}
