.navbar {
	width: 100%;
	display: grid;
	grid-template-columns: 2rem 1fr 2rem;
	grid-template-areas: '. logo burger';
	align-items: center;
	background: var(--main-normal);
	color: var(--heading-white);
	position: sticky;
	top: 0;
	left: 0;
	z-index: 1000;
	padding: 1rem 2rem;
	@include headerFont;

	@include breakpoint('s') {
		grid-template-columns: 12rem 4fr 8rem 4rem;
		grid-template-areas: 'contact logo lang burger';
	}

	a {
		color: var(--heading-white);

		&:hover {
			color: var(--link-light);
		}
	}

	.icon {
		width: 2rem;
		height: 2rem;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	.contact {
		grid-area: contact;
		display: none;

		@include breakpoint('s') {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: 1rem;
		}

		a span {
			display: none;
			visibility: hidden;
			font-size: 0;
		}
	}

	.logo {
		grid-area: logo;
		flex: 1;
		width: 150px;
		justify-self: center;
	}

	.lang {
		grid-area: lang;
		display: none;

		@include breakpoint('s') {
			display: flex;
		}
	}

	.burger {
		grid-area: burger;
		justify-self: end;
		width: 4rem;
		height: 4rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		gap: 0.5rem;
		padding: 0.5rem;

		span {
			display: block;
			width: 100%;
			height: 2px;
			background: var(--heading-white);
			border-radius: 2px;

			&:nth-child(1) {
				width: 2.5rem;
			}

			&:nth-child(2) {
				width: 100%;
			}

			&:nth-child(3) {
				width: 25px;
			}
		}
	}
}
