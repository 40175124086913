.lang {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 0.25rem;

	.divider {
		display: block;
		width: 1px;
		height: 1.5rem;
		background: #fff;
	}

	a {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 2rem;
		height: 2rem;
		opacity: 0.6;

		&.selected {
			text-decoration: underline;
			opacity: 1;
		}
	}
}
