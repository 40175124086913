:root {
	--max-width: 900px;

	--selection-color: #4a4a4a;
	--selection-background: #8ddcb2;

	--site-background: #ffffff;
	--background-primary: #ffffff;
	--background-secondary: #eff0f1;
	--background-tertiary: #0d3928;
	--background-alt: #c3af8a;

	--main-normal: #0d3928;
	--main-dark: #0c331f;
	--main-light: #104529;
	--main-lighter: #24995b;

	--link-normal: #e97878;
	--link-dark: #cf6b6b;
	--link-light: #f68989;

	--heading-dark: #0a0a0a;
	--heading-normal: #161616;
	--heading-light: #242424;
	--heading-white: #ffffff;

	--text-normal: #4a4a4a;
	--text-dark: #3d3d3d;
	--text-light: #8a8a8a;

	--fw-light: 300;
	--fw-regular: 400;
	--fw-medium: 500;
	--fw-semibold: 600;
	--fw-bold: 700;
}
