.label {
	padding: 0;
	position: fixed;
	margin: auto;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	top: 50%;
	right: 0;
	flex-direction: column;
	z-index: 1000;
	background: var(--background-tertiary);
	writing-mode: vertical-rl;
	transform: translateY(-50%) rotate(180deg);

	a {
		display: inline-flex;
		color: var(--heading-white);
		font-size: 1.5rem;
		padding: 1rem 0.5rem;
		transition: padding 0.25s ease;
		cursor: pointer;
	}

	&:hover a {
		padding-left: 1rem;
	}
}
