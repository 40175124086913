.logo {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	img {
		width: 100%;
	}
}
