.main-footer {
	width: 100%;
	background: var(--background-tertiary);
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: auto auto auto 2rem 30rem;
	gap: 2rem;
	justify-items: center;
	grid-template-areas:
		'logo'
		'menu'
		'address'
		'lang'
		'map';
	margin-top: 10rem;

	@include breakpoint('m') {
		margin-top: 20rem;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto auto 2rem 30rem;
		gap: 4rem;
		grid-template-areas:
			'logo logo'
			'menu address'
			'lang lang'
			'map map';
	}

	@include breakpoint('l') {
		grid-template-columns: 1fr 1fr 1.5fr;
		grid-template-rows: 10rem auto 2rem;
		gap: 4rem;
		justify-items: start;
		grid-template-areas:
			'logo logo map'
			'menu address map'
			'lang lang map';
	}

	a {
		color: var(--heading-white);

		&:hover {
			color: var(--link-light);
		}
	}

	.logo,
	.menu,
	.address,
	.lang {
		padding: 0 1rem;

		@include breakpoint('m') {
			padding: 0 2rem;
		}
	}

	.logo {
		grid-area: logo;
		max-width: 22rem;
		width: 100%;
		margin-top: 2rem;
		justify-self: center;
	}

	.menu {
		grid-area: menu;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
			}
		}
	}

	.address {
		grid-area: address;
		color: var(--heading-white);
		font-weight: var(--fw-light);
		display: flex;
		flex-direction: column;
		gap: 1rem;

		.title {
			color: var(--background-alt);
			font-weight: var(--fw-medium);
		}

		.description {
		}

		.streets {
			display: flex;
			flex-direction: column;

			.street {
			}
		}

		.city {
			display: flex;
			flex-direction: row;
			gap: 0.25rem;
		}

		.phone {
		}

		.mail {
			a {
				color: var(--background-alt);
			}
		}
	}

	.lang {
		grid-area: lang;

		@include breakpoint('l') {
			margin-bottom: 2rem;
		}
	}

	.map {
		grid-area: map;
		width: 100%;
		background: var(--background-secondary);
	}
}
