@mixin headerFont {
	font-family: 'Lora', serif;
}

/* breakpoint mixin */
@mixin breakpoint($breakpoint, $direction: $media-direction) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media only screen and (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
			@content;
		}
	} @else if unitless($breakpoint) {
		$breakpoint: $breakpoint + 0px;
		@media only screen and (#{$direction}-width: $breakpoint) {
			@content;
		}
	}
}

/* z-index */
@function z-index($name) {
	$z: null;
	@if type-of($z-layers) == map {
		$z: map-get($z-layers, $name);
	}
	@if type-of($z-layers) == list {
		@for $i from 1 through length($z-layers) {
			@if nth($z-layers, $i) == $name {
				$z: $i;
			}
		}
	}
	@if $z {
		@return $z;
	} @else {
		@error ('Could not find a z-index for `#{$name}`');
	}
}
@mixin z-index($name) {
	z-index: z-index($name);
}
