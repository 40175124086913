// Lora
@font-face {
	font-display: swap;
	font-family: 'Lora';
	font-style: normal;
	font-weight: 400;
	src: url('../../../assets/fonts/lora-v32-latin-regular.woff2') format('woff2'),
		url('../../../assets/fonts/lora-v32-latin-regular.woff') format('woff');
}
@font-face {
	font-display: swap;
	font-family: 'Lora';
	font-style: normal;
	font-weight: 700;
	src: url('../../../assets/fonts/lora-v32-latin-700.woff2') format('woff2'),
		url('../../../assets/fonts/lora-v32-latin-700.woff') format('woff');
}

// Poppins
@font-face {
	font-display: swap;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: url('../../../assets/fonts/poppins-v20-latin-300.woff2') format('woff2'),
		url('../../../assets/fonts/poppins-v20-latin-300.woff') format('woff');
}
@font-face {
	font-display: swap;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: url('../../../assets/fonts/poppins-v20-latin-regular.woff2') format('woff2'),
		url('../../../assets/fonts/poppins-v20-latin-regular.woff') format('woff');
}
@font-face {
	font-display: swap;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: url('../../../assets/fonts/poppins-v20-latin-500.woff2') format('woff2'),
		url('../../../assets/fonts/poppins-v20-latin-500.woff') format('woff');
}
@font-face {
	font-display: swap;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: url('../../../assets/fonts/poppins-v20-latin-600.woff2') format('woff2'),
		url('../../../assets/fonts/poppins-v20-latin-600.woff') format('woff');
}
@font-face {
	font-display: swap;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: url('../../../assets/fonts/poppins-v20-latin-700.woff2') format('woff2'),
		url('../../../assets/fonts/poppins-v20-latin-700.woff') format('woff');
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include headerFont;
	line-height: 1.618em;
	margin: 1.5rem 0 1rem;
	font-weight: var(--fw-regular);
	color: var(--text-dark);
}

h1 {
	font-size: 2.25rem;
}

h2 {
	font-size: 2rem;
}

h3 {
	font-size: 1.75rem;
}

h4 {
	font-size: 1.5rem;
}

h5 {
	font-size: 1.25rem;
}

h6 {
	font-size: 1rem;
}

p {
	margin: 1rem 0;
	color: var(--text-normal);
	line-height: 1.618em;
	font-size: 1rem;
	font-weight: var(--fw-light);

	&.big {
		font-size: 1.5rem;
		font-weight: var(--fw-regular);
		line-height: 1.4;
	}
}

b,
strong {
	color: var(--text-dark);
	font-weight: var(--fw-semibold);
}

a {
	text-decoration: none;
	color: var(--link-normal);
	line-height: 1.618em;
	font-weight: var(--fw-light);
	transition: color 0.25s ease;

	&:hover {
		color: var(--link-light);
	}

	&:active {
		color: var(--link-dark);
	}
}
