.home {
	gap: 10rem;

	@include breakpoint('m') {
		gap: 20rem;
	}

	section {
		display: flex;
		flex-direction: column;
		position: relative;
		gap: 2rem;

		@include breakpoint('m') {
			gap: 4rem;
		}

		.section-title {
			position: relative;
			font-weight: var(--fw-regular);
			font-size: 2rem;
			margin: 0;

			@include breakpoint('l') {
				width: 100%;
				position: absolute;
				top: 0;
				left: calc(-100% - 2rem);
				text-align: right;
				font-size: 1.5rem;
			}
		}

		.intro {
			p {
				font-size: 1.5rem;
				font-weight: var(--fw-regular);
				color: var(--text-normal);
				margin: 0;
			}

			.button {
				margin-top: 2rem;
			}
		}
	}

	.posts {
		display: flex;
		flex-direction: column;
		gap: 6rem;
	}

	.post {
		display: flex;
		flex-direction: column-reverse;
		gap: 1rem;

		@include breakpoint('m') {
			flex-direction: row;
			gap: 2rem;

			&:nth-child(2n) {
				flex-direction: row-reverse;
			}
		}

		&-content {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;

			@include breakpoint('m') {
				gap: 1rem;
			}

			h4 {
				font-size: 2rem;
				margin: 0;

				a {
					color: var(--text-dark);

					&:hover {
						color: var(--main-light);
					}
				}
			}

			p {
				margin: 0;
			}

			.button {
				align-self: flex-start;

				@include breakpoint('m') {
					margin-top: 1rem;
					align-self: flex-end;
				}
			}
		}

		.summary {
			font-size: 1.5rem;
			font-weight: var(--fw-light);
			color: var(--text-normal);
		}

		.image {
			flex: 1;

			img {
				width: 100%;
			}
		}
	}

	.banner {
		width: 100%;
		display: flex;
		flex-direction: column;
		background: var(--background-tertiary);
		color: var(--heading-white);

		@include breakpoint('m') {
			flex-direction: row;
			align-items: center;
		}

		.image {
			height: 250px;
			position: relative;

			@include breakpoint('m') {
				flex: 1.5;
				height: 500px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
			}
		}

		.text {
			flex: 1;
			padding: 4rem 1rem;
			@include headerFont();
			text-transform: uppercase;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			font-size: 1.5rem;
			gap: 2rem;

			@include breakpoint('m') {
				padding: 2rem;
				justify-content: flex-start;
			}

			a {
				color: var(--heading-white);
			}
		}
	}

	.weinstube-swiper {
		.swiper-slide {
			height: 600px;
		}
	}
}
