.hours {
	display: flex;
	flex-direction: column;
	margin: 1.5rem 0;

	.title {
		font-size: 1.5rem;
		@include headerFont;
		margin-bottom: 1rem;
	}

	.data {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto;
		gap: 0.5rem;
		font-weight: var(--fw-light);

		.days {
		}

		.times {
		}

		+ .addition {
			margin-top: 0.5rem;
			margin-bottom: 0;
		}
	}

	.addition {
		font-weight: var(--fw-medium);
		margin-bottom: 0.5rem;
	}
}
