* {
	box-sizing: border-box;
}

::selection {
	color: var(--selection-color);
	background: var(--selection-background);
}

html,
body {
	font-size: 16px;
	-webkit-text-size-adjust: 100%;
	font-family: 'Poppins', sans-serif;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;
	background: var(--site-background);
	color: hsl(209, 18%, 30%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	// padding-top: 100px;
}

.main {
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.content {
	position: relative;
	width: 100%;
	max-width: var(--max-width);
	padding: 0 1rem;
	margin: 0 auto;
	display: flex;
	flex-direction: column;

	@include breakpoint('m') {
		padding: 0 2rem;
	}
}

.back {
	display: block;
	margin-bottom: 1rem;
}

ul {
	padding-left: 1rem;

	li {
		padding: 0.125rem 0;
	}
}

.responsive-img {
	max-width: 100%;
}

img {
	vertical-align: middle;
}

.ratio {
	position: relative;

	&:before {
		display: block;
		content: '';
		width: 100%;
	}

	&--16-5 {
		&:before {
			padding-top: (5 / 16) * 100%;
		}
	}
	&--8-5 {
		&:before {
			padding-top: (5 / 8) * 100%;
		}
	}
	&--4-3 {
		&:before {
			padding-top: (3 / 4) * 100%;
		}
	}
	&--3-2 {
		&:before {
			padding-top: (2 / 3) * 100%;
		}
	}
	&--1-1 {
		&:before {
			padding-top: (1 / 1) * 100%;
		}
	}

	> img,
	> picture,
	> picture > img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}
