.erleben-swiper {
	.swiper-slide {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		@include breakpoint('l') {
			flex-direction: row;
			gap: 2rem;
		}

		.image {
			@include breakpoint('l') {
				flex: 1;
			}
		}

		.description {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 1rem;

			@include breakpoint('l') {
				flex: 1;
			}
		}

		.title {
			@include headerFont();
			font-size: 2rem;
			color: var(--heading-normal);

			a {
				color: var(--heading-normal);
			}
		}

		.text {
			font-weight: var(--fw-light);

			p {
				margin: 0;
			}
		}

		.button {
			align-self: flex-end;
			margin-top: 1rem;
		}
	}
}
