.wine-swiper {
	.swiper-slide {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		@include breakpoint('s') {
			flex-direction: row;
			align-items: flex-end;
		}

		@include breakpoint('m') {
			gap: 4rem;
		}

		.image {
			display: block;
			height: 500px;

			@include breakpoint('s') {
				flex: 1;
				height: 600px;
			}

			img {
				object-fit: contain;
				object-position: center bottom;
			}
		}

		.description {
			flex: 2;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 1rem;
			margin-bottom: 2rem;
		}

		.titles {
			display: flex;
			flex-direction: column;
		}

		.title {
			@include headerFont();
			font-size: 2rem;
			color: var(--heading-normal);

			a {
				color: var(--heading-normal);
			}
		}

		.type {
			@include headerFont();
			font-size: 1.5rem;
			color: var(--text-normal);
		}

		.text {
			font-weight: var(--fw-light);

			p {
				margin: 0;
			}
		}

		.button {
			margin-top: 1rem;
		}
	}
}
