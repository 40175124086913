.button {
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	padding: 0.5rem 1rem;
	background: transparent;
	border: 2px solid var(--main-normal);
	color: var(--main-normal);
	transition: all 0.25s ease;

	&:hover {
		background: var(--main-light);
		color: #fff;
	}

	&:active {
		background: var(--main-dark);
		color: #fff;
	}
}
