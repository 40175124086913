/* breakpoints */
$media-direction: min;
$breakpoints: (
	'default': '',
	'xs': 480px,
	's': 640px,
	'm': 960px,
	'l': 1280px,
	'xl': 2080px
);

/* z-index layers */
$z-layers: (
	'menu': 1000,
	'burger': 1005,
	'mobileNav': 1004
);

/* grid settings */
$max-width: 1280px;
$column-gap: 1rem;
$row-gap: 2rem;

@import 'normalize';
@import 'import';
